import React, { useState, useRef } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Typography,
  Divider,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import PowerOff from '@material-ui/icons/PowerSettingsNewOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ChatIcon from '@material-ui/icons/Chat';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import useRouter from 'utils/useRouter';
import { NotificationsPopover, Breadcrumbs } from 'components';
import { Context } from 'context';
// import Avatar from '@material-ui/core/Avatar';
// import Getinitials from 'utils/getInitials';
// import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
// import Switch from "@material-ui/core/Switch";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { tokenmess } from 'constants/firebase';
import UrlServer from 'constants/urlserver';
// eslint-disable-next-line no-use-before-define

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  notificationsBadge: {
    backgroundColor: colors.orange[500],
    color: 'white'
  },
  top: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    width: '36px',
    height: '36px',
  },
  divider: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.divider,
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, notifList, pageTitle, className, ...rest } = props;
  const [state, dispatch] = React.useContext(Context);
  const classes = useStyles();
  const { history } = useRouter();
  const notificationsRef = useRef(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [value] = React.useState({
    judul: '',
    content: '',
  });

  const [toggleDark, settoggleDark] = useState(state.theme === false ? true : false);
 
  const handleModeChange = () => {
    settoggleDark(!toggleDark);
    dispatch({
      type: 'THEME',
      content: toggleDark,
    });
    localStorage.setItem('tema', toggleDark)
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = () => {
    // console.log("data tokem:", tokenmess)
     const dtlog = JSON.parse(localStorage.getItem('datalog'));
    console.log("hak akses =", dtlog.hak_akses)
    if(dtlog.hak_akses == 'SuperAdmin') {
      value['tipe']='unsubscribe';
      value['token']=tokenmess;
      value['topic']='SuperAdmin';
      console.log("send", value)
      fetch(UrlServer.address + 'api/v1/subscribetoken', {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        },
        body: JSON.stringify(value)
      })
        .then(res => res.json())
    }else if(dtlog.hak_akses == 'CS'){
      value['tipe']='unsubscribe';
      value['token']=tokenmess;
      value['topic']='CS';
      console.log("send", value)
      fetch(UrlServer.address + 'api/v1/subscribetoken', {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        },
        body: JSON.stringify(value)
      })
        .then(res => res.json())
    }else{
      console.log("tidak mendapat notif")
    }
    history.push('/login');
    localStorage.clear('session');
    localStorage.clear('datalog');
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  function unreadMessage() {
    var total = 0
    notifList.forEach(element => {
      if (element.status === true) total += 1
    });
    return total
  }

  const handleprofil = () => {
    history.push('/profil');
  }

  const contentApp = (
    <>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className={classes.top}>
          <Breadcrumbs />
          <Typography variant="h4" style={{ marginTop: '5px' }}>{pageTitle}</Typography>
        </div>

        <div className={classes.flexGrow} />
        
        <IconButton onClick={() => handleModeChange()}>
        <Brightness4Icon/>
        </IconButton>
        {/* <Switch
    checked={toggleDark}
    onChange={handleModeChange}
    name="toggleDark"
    color="default"
/> */}

        <IconButton onClick={() => history.push('/livechat/none')}>
          <ChatIcon />
        </IconButton>

        <IconButton onClick={handleNotificationsOpen} ref={notificationsRef}>
          <Badge badgeContent={unreadMessage()} classes={{ badge: classes.notificationsBadge }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <IconButton ref={anchorRef} onClick={handleToggle}>
          <AccountCircleIcon />
        </IconButton>
        <Popper style={{zIndex:999}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleprofil}>Profile</MenuItem>
                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>

      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifList}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </>
  )

  return (
    <>
      <Hidden lgUp>
        <AppBar {...rest} className={clsx(classes.root, className)} color="inherit">
          {contentApp}
        </AppBar>
      </Hidden>
      <Hidden mdDown>
        {contentApp}
      </Hidden>
      <Divider className={classes.divider} />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  notifList: PropTypes.array,
  pageTitle: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
