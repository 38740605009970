import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Typography } from '@material-ui/core';
import NavBar from './layouts/NavBar';
import TopBar from './layouts/TopBar';
import UrlServer from 'constants/urlserver';
import { Context } from 'context';
import { db } from 'constants/firebase';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import useRouter from 'utils/useRouter';
import firebase, { tokenmess, REACT_APP_VAPID_KEY } from 'constants/firebase';
import { sendingFCM } from 'views/broadcast/SenderNotif';
import { Button } from '@material-ui/core';
// import FCM from "constants/bilassystem-2a77f-firebase-adminsdk-ps68f-a6f6b6e42b.json";
// import {firebaseClient} from 'constants/Sender'

// const socket = io(UrlServer.socket, {
//   transport: ['polling']
// });

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    background: theme.palette.basic,
  },
  navBar: {
    background: theme.palette.primary.main,
    zIndex: 3,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
}));

const getLocalData = (data) => {
  return JSON.parse(localStorage.getItem(data));
};

const Main = props => {
  const { route } = props;
  const router = useRouter();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [last, setLast] = React.useState();
  const [akses, setAkses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(10);
  const [value, setValue] = React.useState({
    judul: '',
    content: '',
  });


  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  React.useEffect(() => {
    // firebase.messaging().getToken({vapidKey:REACT_APP_VAPID_KEY}).then((currentToken) => {

    //   if(currentToken){
    //     console.log("data token di main:",currentToken)
    //     tokenmess = currentToken

    //   }else{
    //     console.log("data tidak dapat")
    //   }
    // }).catch((err)=>{
    //   console.log("error")
    // })



    getLastSync()
    getWithdrawal()


    if (localStorage.getItem('session') == null) {
      router.history.replace('/login');

    } else {

      const dtlog = JSON.parse(localStorage.getItem('datalog'));
      // console.log("isixxxxx", dtlog.hak_akses)

      dispatch({
        type: 'LOGIN',
        content: {
          id: dtlog.id,
          email: dtlog.email,
          nama: dtlog.nama,
          chatname: dtlog.username,
          akses: dtlog.hak_akses,
          hak_akses: dtlog.hak_akses,
          username: dtlog.username,
          data: dtlog
        }


      })
      if (dtlog.hak_akses == 'SuperAdmin' || dtlog.hak_akses == 'Admin' || dtlog.hak_akses == 'CS' || dtlog.hak_akses == 'SalesOfficer' || dtlog.hak_akses == 'Writer' || dtlog.hak_akses == 'Keuangan') {
        getLastLogin()

      } else {
        router.history.replace('/login');
      }

      //   if(dtlog.datalogin.kodereferal !== null) {
      //     router.history.push('/promoter/datareferal')
      //  }

      // console.log("data login:", moment(dtlog.last_login).add('days', 3).format('DD-MM-YYYY'))
      // const a = moment(dtlog.last_login).add('days', 3).format('DD-MM-YYYY');
      // const b = moment(new Date()).format('DD-MM-YYYY');
      // if (b <= a) {
      //   dispatch({
      //     type: 'LOGIN',
      //     content: {
      //         id: dtlog.id,
      //         email: dtlog.email,
      //         nama: dtlog.nama,
      //         chatname: dtlog.username,
      //         akses: dtlog.hak_akses,
      //         hak_akses: dtlog.hak_akses,
      //         username: dtlog.username,
      //         data: dtlog
      //     }


      // })
      //           // fetch(UrlServer.address + 'api/v1/users/lastlogin/' + dtlog.id, {
      //           // method: 'put',
      //           // headers: {
      //           //     'Accept': 'application/json, text/plain, */*',
      //           //     'Content-Type': 'application/json',
      //           //     'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
      //           // },
      //           // body: JSON.stringify({ id: dtlog.id })
      //           // })
      //           // .then(res => res.json()).then(res => {
      //           //     if (res.value == 1) {
      //           //     swal({ title: 'Data berhasil diupdate kunujungan.', icon: "success" })

      //           //     }
      //           // });

      // setAkses(dtlog.hak_akses);
      // } else {
      //   router.history.replace('/login');
      //   localStorage.clear('session');
      //   localStorage.clear('datalog')
      // }


    }
    // console.log("data route", state.login.akses)

  }, [])

  const testii = () => {
    value['tipe'] = 'subscribe';
    value['token'] = tokenmess;
    value['topic'] = 'CSBilas';
    console.log("send", value)
    fetch(UrlServer.address + 'api/v1/subscribetoken', {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
      },
      body: JSON.stringify(value)
    })
      .then(res => res.json())
      .then(res => {
        if (res.value == 1) {
          swal({ title: 'Berhasil disimpan.', icon: 'success' }).then(ok => {
            // history.push('/broadcast/');
          });
        }
      });


  }

  const getLastLogin = () => {
    const dtlog = JSON.parse(localStorage.getItem('datalog'));
    fetch(UrlServer.address + 'api/v1/users/' + dtlog.id, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
        'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
      }
    }).then(res => res.json().then(resp => {
      const dtlog = JSON.parse(localStorage.getItem('datalog'));
      const a = moment(resp.result[0].last_login).add(3, 'days').format('YYYY-MM-DD');
      const b = moment(new Date()).format('YYYY-MM-DD');
      if (b <= a) {
        dispatch({
          type: 'LOGIN',
          content: {
            id: dtlog.id,
            email: dtlog.email,
            nama: dtlog.nama,
            chatname: dtlog.username,
            akses: dtlog.hak_akses,
            hak_akses: dtlog.hak_akses,
            username: dtlog.username,
            data: dtlog
          }


        })

        setAkses(dtlog.hak_akses);
        // console.log("data tokem:", tokenmess)
        // const dtlog = JSON.parse(localStorage.getItem('datalog'));
        console.log("hak akses =", dtlog.hak_akses)
        // if (dtlog.hak_akses == 'SuperAdmin') {
        //   value['tipe'] = 'subscribe';
        //   value['token'] = tokenmess;
        //   value['topic'] = 'SuperAdmin';
        //   console.log("send", value)
        //   fetch(UrlServer.address + 'api/v1/subscribetoken', {
        //     method: 'post',
        //     headers: {
        //       Accept: 'application/json, text/plain, */*',
        //       'Content-Type': 'application/json',
        //       'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        //     },
        //     body: JSON.stringify(value)
        //   })
        //     .then(res => res.json())
        // } else if (dtlog.hak_akses == 'CS') {
        //   value['tipe'] = 'subscribe';
        //   value['token'] = tokenmess;
        //   value['topic'] = 'CS';
        //   console.log("send", value)
        //   fetch(UrlServer.address + 'api/v1/subscribetoken', {
        //     method: 'post',
        //     headers: {
        //       Accept: 'application/json, text/plain, */*',
        //       'Content-Type': 'application/json',
        //       'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
        //     },
        //     body: JSON.stringify(value)
        //   })
        //     .then(res => res.json())
        // } else {
        //   console.log("tidak mendapat notif")
        // }
      }
      else {

        const dtlog = JSON.parse(localStorage.getItem('datalog'));
        console.log("hak akses =", dtlog.hak_akses)
        if (dtlog.hak_akses == 'SuperAdmin') {
          value['tipe'] = 'unsubscribe';
          value['token'] = tokenmess;
          value['topic'] = 'SuperAdmin';
          console.log("send", value)
          fetch(UrlServer.address + 'api/v1/subscribetoken', {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
            },
            body: JSON.stringify(value)
          })
            .then(res => res.json())
        } else if (dtlog.hak_akses == 'CS') {
          value['tipe'] = 'unsubscribe';
          value['token'] = tokenmess;
          value['topic'] = 'CS';
          console.log("send", value)
          fetch(UrlServer.address + 'api/v1/subscribetoken', {
            method: 'post',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
            },
            body: JSON.stringify(value)
          })
            .then(res => res.json())
        } else {
          console.log("tidak mendapat notif")
        }

        router.history.replace('/login');
        localStorage.clear('session');
        localStorage.clear('datalog')
      }
    }))
  }

  const getLastSync = () => {
    fetch(UrlServer.address + 'api/v1/lastsync', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
        'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
      }
    }).then(res => res.json().then(resp => {
      if (resp.result[0] == null) {
        setLast('SYNC FIRST !')
      } else {
        var data = <Typography style={{ textTransform: 'uppercase', color: '#fff' }}> {moment(resp.result[0].created_date).format('DD/MM/YYYY HH:mm')}</Typography>
        setLast(data)
      }
    }))
  }

  async function getWithdrawal() {
    const res = await fetch(UrlServer.address + 'api/v2/withdraw', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
      },
    });
    res.json().then( async res => {
      const list = res.result.filter(elem => elem.status == "PENDING")
      var num = 0;
      var po_data = []
     await fetch(UrlServer.address + 'api/v2/blaztap/preorder', {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
        },
      }).then(res =>
        res.json()).then(resp => {
          // var num2 = 0;
          var preorder = resp.result;
          if(preorder.length > 0){
            preorder.forEach(element => {
            // element['nomor'] = element.id ? num2 += 1 : 0;
            element['status'] = true;
            element['type'] = 'preorder';
            element['waktu'] = element.created_date;
            element['title'] = 'PO Request From ' + element.nama_owner;
            po_data.push(element)
          });
          // dispatch({
          //   type: 'NOTIF',
          //   content: preorder
          // })
        }
      })
      
      list.forEach(element => {
        // element['nomor'] = element.id ? num += 1 : 0;
        element['status'] = true;
        element['type'] = 'order';
        element['waktu'] = element.dibuat_tgl;
        element['title'] = 'Withdrawal Request From ' + element.jenis;

      });
      var merge = [...list, ...po_data]
      merge.forEach(element => {
        element['nomor'] = element.id ? num += 1 : 0;
      })
      
      dispatch({
        type: 'NOTIF',
        content: merge
      })

      // console.log("notef",state.notif)
    })
  }

  // React.useEffect(() => {
  // socket.on("newRoom", data => {
  //   console.log('new room', data)
  //   addNotif('livechat', 'Chat baru dari ' + data.user)
  // })

  // socket.on("newFeedback", data => {
  //   console.log('new feedback', data)
  //   addNotif('feedback', data.jenis + ' baru dari ' + data.email)
  // })
  // dispatch({
  //   type: 'NOTIF',
  //   content: getLocalData("notifData") ? getLocalData("notifData") : []
  // })

  // }, [])

  // React.useEffect(() => {
  //   getDataOutlet()
  //   getDataPemilik()
  //   getDataStaff()
  //   getDataPembayaran()
  // }, [])

  // const getDataOutlet = () => {
  //   if (getLocalData("outletData") == null || getLocalData("outletData") == '[]' || getLocalData("outletData") == '') {
  //     console.log("server data loaded")
  //     fetchOutlet()
  //   } else {
  //     console.log("local data loaded")
  //     console.log('outlet -> ', getLocalData("outletData"))
  //     dispatch({
  //       type: 'OUTLET',
  //       content: getLocalData("outletData")
  //     })
  //   }
  // }

  // const getDataPemilik = () => {
  //   if (getLocalData("pemilikData") == null || getLocalData("pemilikData") == '[]' || getLocalData("pemilikData") == '') {
  //     console.log("server data loaded")
  //     fetchPemilik()
  //   } else {
  //     console.log("local data loaded")
  //     console.log('pemilik -> ', getLocalData("pemilikData"))
  //     dispatch({
  //       type: 'OWNER',
  //       content: getLocalData("pemilikData")
  //     })
  //   }
  //   // console.log("data route", state.login.akses)
  //   setAkses(state.login.akses);
  // })



  const startSync = () => {
    // console.log('start syncing ...')
    // swal({ text: "Sinkronisasi data server ...", buttons: false, timer: 5000 })
    setOpenNavBarMobile(false);

    postDataOutlet()

    // localStorage.setItem('lastSync', moment().format("DD/MM/YY HH:mm:ss"))
    // setLast(localStorage.getItem('lastSync'))
  }

  const restartApi1 = () => {
    swal({ title: "Yakin Restart API ?", icon: "error", buttons: ["Batal", "Ya"], dangerMode: true })
      .then((willrestart) => {
        if (willrestart) {
          swal({ text: "sedang me-restart API ...", buttons: false, timer: 10000 })
          fetch(UrlServer.address + 'api/reload', {
            method: 'post',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
              'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
            }
            // body: JSON.stringify({
            //   id_user : state.login.data.id,
            //   nama : state.login.data.nama
            // })
          }).then(res => res.json().then(sync => {
            console.log("restart api 1", sync)
            // setLoading(false)
            // swal({ title: 'API Berhasil Direstart', icon: "success"})
          }))

          setTimeout(function () {
            restartApi2()
          }, 10000);
        }
      })
  }

  const restartApi2 = () => {
    fetch(UrlServer.address2 + 'api/reload', {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
        'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
      }
      // body: JSON.stringify({
      //   id_user : state.login.data.id,
      //   nama : state.login.data.nama
      // })
    }).then(res => res.json().then(sync => {
      console.log("restart api 2", sync)
      swal({ title: 'API Berhasil Direstart', icon: "success" })
    }))
  }

  const postDataOutlet = () => {
    setLoading(true)
    const timer = setInterval(() => {
      swal({ text: "Sinkronisasi data server ...", buttons: false })
    });

    fetch(UrlServer.address + 'api/sinkron', {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
        'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
      },
      body: JSON.stringify({
        tipe: 'outlet',
        mode: 'all list'
      })
    })
      .then(rest => {

        // console.log("hasilpost", rest)

        fetch(UrlServer.address + 'api/sinkron', {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
            'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
          },
          body: JSON.stringify({
            tipe: 'pemilik',
            mode: 'all list'
          })
        })
          .then(rest2 => {

            // console.log("hasilowner", rest2)

            fetch(UrlServer.address + 'api/sinkron', {
              method: 'post',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
                'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
              },
              body: JSON.stringify({
                tipe: 'staff',
                mode: 'all list'
              })
            })
              .then(res3 => {
                // console.log("hasilstaff",res3)

                fetch(UrlServer.address + 'api/v1/lastsync', {
                  method: 'post',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt',
                    'x-sinkron-token': 'wf9=D]~t#eG.}H`^U}<VBkNwa2pQ-d(5-6:`/Xsv=sVQvS*19X4q~)Z<(;U)GIFAtIH5'
                  },
                  body: JSON.stringify({
                    id_user: state.login.data.id,
                    nama: state.login.data.nama
                  })
                }).then(res => res.json().then(sync => {
                  var waktu = <p style={{ textTransform: 'uppercase' }}> {moment(sync.result[0].created_date).add(7, 'hour').format('DD/MM/YYYY HH:mm')}</p>
                  setLast(waktu)
                  setLoading(false)
                  clearInterval(timer);
                  swal({ title: 'Berhasil Disinkron', icon: "success" })
                }))
              })
          })

      })
  }

  const testdpdnc = () => {
    value['tipe'] = 'subscribe';
    value['token'] = tokenmess;
    value['topic'] = 'CSBilas';
    console.log("send", value)
    fetch(UrlServer.address + 'api/v1/sendtotopic', {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
      },
      body: JSON.stringify(value)
    })
      .then(res => res.json())
      .then(res => {
        if (res.value == 1) {
          swal({ title: 'Berhasil disimpan.', icon: 'success' }).then(ok => {
            // history.push('/broadcast/');
          });
        }
      });
  }

  const sendtoCS = () => {
    value['tipe'] = 'subscribe';
    value['token'] = tokenmess;
    value['topic'] = 'CS';
    value['title'] = 'Hallo CS Bilas';
    value['body'] = 'Ada Perubahan Bug Silahkan di cek!'
    console.log("send", value)
    fetch(UrlServer.address + 'api/v1/sendtotopic', {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
      },
      body: JSON.stringify(value)
    })
      .then(res => res.json())
      .then(res => {
        if (res.value == 1) {
          swal({ title: 'Berhasil disimpan.', icon: 'success' }).then(ok => {
            // history.push('/broadcast/');
          });
        }
      });
  }

  const sendtoAdmin = () => {
    value['tipe'] = 'subscribe';
    value['token'] = tokenmess;
    value['topic'] = 'SuperAdmin';
    value['title'] = 'Hallo Admin Bilas';
    value['body'] = 'Ada Bug Silahkan di cek!'
    console.log("send", value)
    fetch(UrlServer.address + 'api/v1/sendtotopic', {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-access-token': 'sjgyfne73592643gedudney3628465hgrdt'
      },
      body: JSON.stringify(value)
    })
      .then(res => res.json())
      .then(res => {
        if (res.value == 1) {
          swal({ title: 'Berhasil disimpan.', icon: 'success' }).then(ok => {
            // history.push('/broadcast/');
          });
        }
      });
  }




  return (
    // loading ? <LinearProgress color="primary" /> :
    <div className={classes.root}>
      <NavBar
        className={classes.navBar}
        onMobileClose={handleNavBarMobileClose}
        openMobile={openNavBarMobile}
        lastSync={last}
        handlingSinkron={startSync}
        handleRestartAPI={restartApi1}
      />
      <div className={classes.container}>
        <TopBar
          className={classes.topBar}
          notifList={state.notif}
          pageTitle={""}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        {/* {akses == "SuperAdmin"? <Button onClick={sendtoCS}>kirim notif ke CS</Button>:akses == "CS"? <Button onClick={sendtoAdmin}>Kirim notif ke Admin</Button>:''}
        sbdksjabdk = <Button onClick={testii}>testt</Button>
        <Button onClick={testdpdnc}>test notif dpndc</Button> */}
        {/* ---{akses}-- */}
        {akses == "SuperAdmin" ?
          <main className={classes.content}>
            <Suspense fallback={<LinearProgress color="secondary" />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </main> : akses == "Admin" ?
            <main className={classes.content}>
              <Suspense fallback={<LinearProgress color="secondary" />}>
                {renderRoutes(route.admin)}
              </Suspense>
            </main> : akses == "CS" ?
              <main className={classes.content}>
                <Suspense fallback={<LinearProgress color="secondary" />}>
                  {renderRoutes(route.cs)}
                </Suspense>
              </main> :
              akses == "SalesOfficer" ?
              <main className={classes.content}>
                <Suspense fallback={<LinearProgress color="secondary" />}>
                  {renderRoutes(route.sales_officer)}
                </Suspense>
              </main>:
              akses == "Writer" ?
              <main className={classes.content}>
                <Suspense fallback={<LinearProgress color="secondary" />}>
                  {renderRoutes(route.writer)}
                </Suspense>
              </main>:
               <main className={classes.content}>
               <Suspense fallback={<LinearProgress color="secondary" />}>
                 {renderRoutes(route.keuangan)}
               </Suspense>
             </main>}
      </div>
    </div>
  );
};

Main.propTypes = {
  route: PropTypes.object
};

export default Main;
